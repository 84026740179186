@import "../../node_modules/bootstrap/scss/functions";

$font-family-sans-serif: "inter", sans-serif;
$border-radius: 2px;
$spacer: 1rem; // 16px
$spacers: (
  0: 0,
  1: $spacer * .25,
  //4px
  2: $spacer * .5,
  //8px
  3: $spacer,
  //16px
  4: $spacer * 1.5,
  //24px
  5: $spacer * 2,
  //32px
  6: $spacer * 3,
  //48px
  7: $spacer * 3.5,
  //56px
  8: $spacer * 4,
  //64px
  9: $spacer * 5,
  //80px
);
@import "../../node_modules/bootstrap/scss/variables";
//----------------------------------------------------
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
//----------------------------------------------------
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
//----------------------------------------------------
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
//----------------------------------------------------
@import "../../node_modules/bootstrap/scss/utilities/api";
//import bootstrap icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";



.accordion {
  --bs-accordion-bg: var(--Light-Grey-2);
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 40px;
  --bs-accordion-btn-padding-y: 24px;
  --bs-accordion-btn-color: var(--Black);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-focus-box-shadow: transparent;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: var(--Black);
  --bs-accordion-active-bg: var(--Light-Grey-2);
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.6;
}

.modal {
  --bs-modal-width: 860px;
  --bs-modal-border-radius: 2px;
  --bs-modal-header-padding: 32px;
  --bs-modal-header-border-width: 0;
  --bs-modal-padding: 32px;
}

.btn {
  --bs-btn-font-weight: 800;
  --bs-btn-padding-x: 24px !important;
  --bs-btn-padding-y: 8px;
}

.btn-primary {
  --bs-btn-color: var(--White);
  --bs-btn-bg: var(--Event-Orange-2);
  --bs-btn-border-color: var(--bs-btn-bg);
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--Event-Orange-1);
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-focus-shadow-rgb: var(--bs-btn-hover-bg);
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
}

.btn-outline-primary {
  --bs-btn-color: var(--Event-Orange-2);
  --bs-btn-border-color: var(--bs-btn-color);
  --bs-btn-hover-color: var(--White);
  --bs-btn-hover-bg: var(--bs-btn-color);
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-focus-shadow-rgb: var(--bs-btn-hover-bg);
  --bs-btn-active-color: var(--bs-btn-hover-bg);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
  --bs-btn-active-shadow: none;
}

.btn-outline-secondary {
  --bs-btn-color: var(--Event-Blue-1);
  --bs-btn-border-color: var(--Event-Blue-1);
  --bs-btn-hover-color: var(--Event-Orange-1);
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: var(--bs-btn-hover-color);
  --bs-btn-focus-shadow-rgb: var(--bs-btn-hover-color);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--bs-btn-hover-color);
  --bs-btn-active-shadow: none;
}

.btn-info {
  --bs-btn-color: var(--White);
  --bs-btn-bg: var(--Event-Blue-2);
  --bs-btn-border-color: var(--bs-btn-bg);
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--Event-Blue-1);
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-focus-shadow-rgb: var(--bs-btn-hover-bg);
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
}
.navbar-toggler:focus {
 box-shadow: none;
}

.container-fluid {
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (min-width: 1130px) {
    padding: 0 64px;
  }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 800;
  line-height: 1.2;
}

/*  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem;
    }
  } */

/* h2, .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem;
    }
  } */

h3,
.h3 {
  font-size: calc(2.05rem + 0.6vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 2.5rem;
  }
}

h4,
.h4 {
  font-size: calc(1.55rem + 0.6vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 2rem;
  }
}

h5,
.h5 {
  font-size: calc(1.15rem + 0.6vw);
}

@media (min-width: 1200px) {

  h5,
  .h5 {
    font-size: 1.6rem;
  }
}

/* h6, .h6 {
    font-size: 1rem;
  } */